import styles from '@styles/Footer/menu.module.scss';
import { getAppConfiguration } from '@utils/browserEnv';
import { Spin } from 'antd';
import Link from 'next/link';
import React from 'react';
import iconMap from 'sailor-ui/iconMap';
import { useFeaturesPages } from 'services/hooks/queries/useFeaturesPages';
const { appCode } = getAppConfiguration();

type Props = {};

const FeaturesMenu: React.FC<Props> = () => {
  const { data, isLoading } = useFeaturesPages({ limit: 100, appCode });

  const items = data?.featuresPages?.data;

  const menuItems =
    items !== undefined &&
    items.map((item) => ({
      key: item?.attributes?.slug,
      icon: iconMap(item?.attributes?.icon?.name),
      label: <Link href={`/features/${item?.attributes?.slug}`}>{item?.attributes?.title}</Link>,
      style: { minWidth: '220px' },
    }));

  if (isLoading) return <Spin />;

  return (
    <div className={styles.container}>
      <div className={styles.container__title}>
        <h4>Features</h4>
      </div>

      <ul className={styles.container__menu}>
        {menuItems.map((item) => (
          <li key={item.key} className={styles.container__item__more}>
            <div>
              {item.icon} {item.label}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FeaturesMenu;
